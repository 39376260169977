* {
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.template-form {
  display: flex;
  overflow-x: scroll;
  gap: 1rem;
}

.template-form::-webkit-scrollbar {
  display: none;
}

.ag-watermark {
  opacity: 0 !important;
}

.sumTable .ag-center-cols-container > div .ag-selection-checkbox {
  /* display:none !important; */
  opacity: 0;
}

.sumTable .ag-header-container > div .ag-header-select-all {
  /* display:none !important; */
  opacity: 0;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.MuiBox-root > .react-datepicker-wrapper {
  width: auto !important;
}
